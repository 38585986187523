import { ChangeDetectorRef, Component, HostListener, inject, Input } from '@angular/core';
import { Story } from 'src/app/models/Story';
import { Sentiment } from 'src/app/models/Sentiment';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { StoryModalComponent } from '../modals/story-modal/story-modal.component';
import { CreditSentimentAPIService } from 'src/app/services/css-api-service.service';

@Component({
  selector: 'app-article-tile',
  templateUrl: './article-tile.component.html',
  styleUrls: ['./article-tile.component.css']
})
export class ArticleTileComponent {

  environment = environment;

  @Input() story!: Story;

  expandText: string = 'Show More ∨'
  showMore = false;

  private positiveTagStyle = {
    borderColor: 'rgb(99, 148, 67)',
    background: 'rgba(131,181,98,.15)',
    color: 'rgb(99, 148, 67)'
  }

  private negativeTagStyle = {
    borderColor: 'rgb(210, 70, 70)',
    background: 'rgba(210, 70, 70, .15)',
    color: 'rgb(210, 70, 70)'
  }

  private neutralTagStyle = {
    border: 'none',
    padding: '4px 12px',
    background: '#EEEEEE',
    color: '#666666'
  }

  tagsContainerStyle = {
    height: '50px'
  }

  constructor(private css_api_service: CreditSentimentAPIService, private cdr: ChangeDetectorRef){}

  readonly dialog = inject(MatDialog);

  openDialog(story: Story): void {
    let modalRef = this.dialog.open(StoryModalComponent, {
      data: {
        requestNewsEdgeArticle: this.requestNewsEdgeArticleInfo.bind(this)
      },
      panelClass: 'custom-dialog-container',
      autoFocus: 'dialog',
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '50ms',
    });
    modalRef.componentInstance.story = story;
  }

  
  ngAfterViewInit() {
    this.displayShowMoreText();
  }

  expandTags(){
    switch(this.expandText) {
      case 'Show More ∨' : 
        this.tagsContainerStyle.height = 'unset';
        this.expandText = 'Show Less ∧';
        break;
      case 'Show Less ∧' : 
        this.tagsContainerStyle.height = '50px';
        this.expandText = 'Show More ∨'
        break;
    }
  }

  @HostListener('window:resize', ['$event'])
  displayShowMoreText() {
    let el = document.getElementById(this.story.id.concat('_article_container'))!
    var isOverflowing = el.clientWidth < el.scrollWidth 
      || el.clientHeight < el.scrollHeight || el.clientHeight > 50;
    this.showMore = isOverflowing;
    this.cdr.detectChanges();
  }

  applyTagStyle(sentiment: Sentiment){
    switch(sentiment){
      case Sentiment.POSITIVE : return this.positiveTagStyle;
      case Sentiment.NEUTRAL : return this.neutralTagStyle;
      case Sentiment.NEGATIVE : return this.negativeTagStyle;
    }
  }

  getDateString(date: Date) {
    return "As of " + date.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC'})
  }

  formatSummary(summary: String): String {
    if(summary.length > 256) {
      return summary.substring(0, 256).concat('...');
    }
    else {
      return summary;
    }
  }

  private requestNewsEdgeArticleInfo(articleID: string){
    return this.css_api_service.requestNewsEdgeArticle(articleID);
  }

}
