import { Component, EventEmitter, Output } from '@angular/core';
import { CreditSentimentAPIService } from 'src/app/services/css-api-service.service';
import { Message } from 'primeng/api/message';
import { ScoreResponse } from 'src/app/models/ScoreResponse';


@Component({
  selector: 'app-sentiment-score-card',
  templateUrl: './sentiment-score-card.component.html',
  styleUrls: ['./sentiment-score-card.component.css']
})
export class SentimentScoreCardComponent {
  @Output() toastMessage: EventEmitter<Message> = new EventEmitter;

  score: number = 0;
  date: Date = new Date();
  loading: boolean = false;
  networkError = true;
  shouldShowTooltip = false;
  noData = false;

  tooltip: string = 'Credit Sentiment Score is a measure of the amount of recent negative credit-relevant news there is available for a company.';
  informationTooltipID: string = 'sentiment-score-card-tooltip-id';
  creditSentimentAPIService: CreditSentimentAPIService;

  constructor(cssApiService: CreditSentimentAPIService){
    this.creditSentimentAPIService = cssApiService;
  }

  refreshComponent(){
    this.score = 0;
    this.loading = true;

    let reqStartDate = new Date();
    reqStartDate.setDate(-7);

    this.creditSentimentAPIService.requestScores({
      entityIdentifier: '',
      startDate: reqStartDate,
      endDate: new Date()
    }).subscribe({
      next: (result: ScoreResponse) => {
        let scoreAndEvent = {score: 0, date: new Date()}
        if(result.scores.length > 0){
          scoreAndEvent = result.scores.pop()!
          this.noData = false;
        } else {
          this.noData = true;
        }
        this.score = scoreAndEvent.score;
        this.date = scoreAndEvent.date;
        this.networkError = false;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false
        this.networkError = true;
        this.toastMessage.emit({ severity: 'error', summary: 'Error', detail: 'Network Error: Please contact network administrator' })
      }
    });
  }

  getSentimentText(score: number) {
    if(this.noData) return 'No recent news found';
    if(score == 0) return 'No recent negative credit news found';
    if(score <= 3) return "Minimal recent negative credit-relevant news";
    if(score <= 5) return "Some recent negative credit-relevant news";
    if(score <= 15) return "Significant recent negative credit-relevant news";
    else return "Severe recent negative credit-relevant news"
  }

  getColorFromScore() {
    if(this.noData) return 'grey'
    if(this.score <= 3) return 'rgb(131, 181, 98)';
    if(this.score <= 5) return 'rgb(196, 177, 2)';
    if(this.score <= 15) return  '#ff8800';
    else return "rgb(210, 70, 70)"
  }

  getDateString() {
    return "As of " + this.date.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC'})
  }

  indicatorPlacement(){
    if(this.score <= 3) return this.score * (53/3);
    if(this.score <= 5) return (this.score-3) * (60/2) + 54;
    if(this.score <= 15) return (this.score-5) * (59/10) + (115);
    else return ((this.score-15) * .55) + (230-55)
  }
}
