<div class="news-feed-container">
    <div class="news-feed-title">News Feed</div>
    <div class="news-feed-content">
        <app-news-feed-sidebar
            [resetFilters]="resetFilters"
            [categories]="storiesResponse.filters"
            [loading]="loading"
            (filter)="filter($event)">
        </app-news-feed-sidebar>
        <div class="news-feed-article-list">
            <svg *ngIf="loading" class="news-feed-spinner" viewBox="0 0 50 50">
                <circle class="news-feed-path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
            <ng-template *ngIf="!loading" ngFor let-story [ngForOf]="storiesResponse.stories" let-i="index">
                <app-article-tile style="width: 80%" [story]="story"></app-article-tile>
            </ng-template>
            <mat-paginator class="news-feed-paginator"
                (page)="handlePageEvent($event)"
                [length]="this.storiesResponse.stories.length > 0 ? articleCount : 0"
                [pageSize]="this.storiesResponse.stories ? pageSize : 0"
                [showFirstLastButtons]="true"
                [pageSizeOptions]="true ? pageSizeOptions : []"
                [hidePageSize]="false"
                [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator>
        </div>
    </div>
</div>
