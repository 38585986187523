import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Highcharts from 'highcharts'
import HC_exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
import { Message } from 'primeng/api/message';
import { ScoreEntry } from 'src/app/models/ScoreEntry';

HC_exporting(Highcharts);
offlineExporting(Highcharts);

@Component({
  selector: 'app-sentiment-score-chart',
  templateUrl: './sentiment-score-chart.component.html',
  styleUrls: ['./sentiment-score-chart.component.css']
})
export class SentimentScoreChartComponent {

  @Output() toastMessage: EventEmitter<Message> = new EventEmitter;

  @Output() zoomEvent: EventEmitter<any> = new EventEmitter;

  @Input() data: Array<ScoreEntry> = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  chartRef!: Highcharts.Chart;

  ngOnChanges(){
    this.chartOptions = this.createChart();
    this.chartRef = Highcharts.charts.find(chartChild => {
      return chartChild?.options.chart?.type == 'line'
    })!;
  }

  public getPNG(companyName: string, queryStartDate: Date, queryEndDate: Date){
    return this.chartRef.getSVG(
      {
        chart: {
          backgroundColor: "white",
          height: 350
        },
        title: {
          text: `Credit Sentiment Score - ${companyName}  | Date Range (${queryStartDate.toLocaleDateString('en-us', {timeZone: 'UTC'})}-${queryEndDate.toLocaleDateString('en-us', {timeZone: 'UTC'})})`
        }
      }
    )
  }


  private formatDate(date: Date) {
        return date.toLocaleDateString('en-us', {day: 'numeric', month: 'short', year: 'numeric', timeZone: "UTC"})
  }

  private createChart() {
    let options: Highcharts.Options;
    options = {
      chart: {
        type: 'line',
        backgroundColor: undefined,
        events: {
          selection: (event) => {
            this.zoomEvent.emit({
              queryStartDate: this.data[Math.floor(event.xAxis[0].min)].date,
              queryEndDate: this.data[Math.floor(event.xAxis[0].max)].date
            });
            return false;
          }
        },
        zooming: {
          type: 'x'
        }
      },
      exporting: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        offset: 20,
        crosshair: {
          dashStyle: 'ShortDash'
        },
        zoomEnabled: true,
        lineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
            enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
      },
      yAxis: {
        crosshair: {
          dashStyle: 'ShortDash'
        },
        zoomEnabled: false,
        labels: {
          style: {
            fontSize: '12px'
          }
        },
        gridLineDashStyle: 'ShortDash',
        max: 100,
        min: 0,
        tickInterval: 20,
        title: {
          text: 'Credit Sentiment Score'
        },
        height: 250
      },
      plotOptions: {
        line: {
          color: '#005EFF',
          lineWidth: 1
        },
        series: {
          marker: {
            enabled: false
          }
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [
        {
          type: 'line',
          name: 'sentiment score',
          data: this.data.map(entry => [this.formatDate(entry.date), Math.round(entry.score)])
        }
      ],
      tooltip: {
        xDateFormat: '%Y-%m-%d'
      }
    }
    return options;
  }

}
